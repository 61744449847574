import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaHome,
  FaUserEdit,
  FaBook,
  FaHeart,
  FaStar,
  FaClipboard,
  FaShoppingCart,
  FaQuestionCircle,
  FaRocket,
  FaSync,
  FaMoneyBill,
  FaVial,
} from 'react-icons/fa'; // Importamos los iconos necesarios

const MiPerfil = () => {
  return (
    <div className="flex min-h-screen bg-[#030712] text-white">
      {/* Sidebar de navegación */}
      <aside className="w-1/4 bg-gray-900 border-r border-gray-700 p-6">
        <div className="text-center mb-8">
          <div className="w-20 h-20 rounded-full bg-blue-900 text-white mx-auto flex items-center justify-center text-3xl font-bold">
            ES
          </div>
          <h2 className="mt-2 text-xl font-semibold">Erick Sanchez</h2>
          <p className="text-yellow-400 mt-1">★★★★★</p>
        </div>

        <nav className="space-y-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaHome className="mr-2" /> Dashboard
          </NavLink>
          <NavLink
            to="/perfil"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaUserEdit className="mr-2" /> Editar Perfil
          </NavLink>
          <NavLink
            to="/classroom"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaBook className="mr-2" /> Mis Cursos
          </NavLink>
          <NavLink
            to="/wishlist"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaHeart className="mr-2" /> Lista de deseos
          </NavLink>
          <NavLink
            to="/reviews"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaStar className="mr-2" /> Reviews
          </NavLink>
          <NavLink
            to="/exams"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaClipboard className="mr-2" /> Exámenes
          </NavLink>
          <NavLink
            to="/orders"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaShoppingCart className="mr-2" /> Mis Pedidos
          </NavLink>
          <NavLink
            to="/faq"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaQuestionCircle className="mr-2" /> Preguntas y Respuestas
          </NavLink>

          <h3 className="mt-6 font-bold text-gray-400">Instructor</h3>
          <NavLink
            to="/my-courses"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaRocket className="mr-2" /> My Courses
          </NavLink>
          <NavLink
            to="/updates"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaSync className="mr-2" /> Actualizaciones
          </NavLink>
          <NavLink
            to="/withdrawals"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaMoneyBill className="mr-2" /> Withdrawals
          </NavLink>
          <NavLink
            to="/attempts"
            className={({ isActive }) =>
              isActive
                ? 'flex items-center bg-blue-800 text-white p-2 rounded'
                : 'flex items-center hover:bg-gray-700 p-2 rounded text-gray-300'
            }
          >
            <FaVial className="mr-2" /> Intentos de prueba
          </NavLink>
        </nav>
      </aside>

      {/* Contenido principal */}
      <main className="flex-1 p-8 bg-[#030712]">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold">Editar Perfil</h1>
          <button className="border border-blue-900 text-blue-900 px-4 py-2 rounded hover:bg-blue-900 hover:text-white">
            + Create a New Course
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p>
              <strong>Fecha de registro:</strong> 09/09/2020 2:14 pm
            </p>
            <p className="mt-2">
              <strong>Nombre:</strong> Erick
            </p>
            <p className="mt-2">
              <strong>Apellidos:</strong> Sanchez
            </p>
            <p className="mt-2">
              <strong>Nombre de usuario:</strong> esanchez
            </p>
            <p className="mt-2">
              <strong>Email:</strong> esanchez.sistec@gmail.com
            </p>
          </div>

          <div>
            <p>
              <strong>WhatsApp:</strong> -
            </p>
            <p className="mt-2">
              <strong>Skill/Occupation:</strong> -
            </p>
            <p className="mt-2">
              <strong>Biografía:</strong> -
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MiPerfil;
