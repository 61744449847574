// src/components/Faqs.js
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Iconos para el acordeón

const faqsData = [
  {
    question: '¿Qué tipo de contenido ofrece Comedy Lab?',
    answer:
      'Comedy Lab ofrece cursos de stand-up, talleres de escritura humorística, creación de chistes, y mucho más. Nuestro contenido está diseñado para todas las edades y niveles de experiencia.',
  },
  {
    question: '¿Cómo puedo acceder a los cursos?',
    answer:
      'Puedes acceder a los cursos inscribiéndote en nuestra plataforma. Solo necesitas crear una cuenta y seleccionar el curso que más te interese.',
  },
  {
    question: '¿Los cursos son en vivo o grabados?',
    answer:
      'Ofrecemos ambas modalidades. Puedes unirte a cursos en vivo o disfrutar de contenido grabado que puedes ver en cualquier momento.',
  },
  {
    question: '¿Se ofrece algún certificado al completar los cursos?',
    answer:
      'Sí, al completar cualquier curso, recibirás un certificado digital que reconoce tus logros en Comedy Lab.',
  },
  {
    question: '¿Hay algún plan de membresía?',
    answer:
      'Sí, contamos con planes de membresía que te permiten acceder a todo el contenido de forma ilimitada durante el tiempo que dure tu suscripción.',
  },
];

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="space-y-6">
      {faqsData.map((faq, index) => (
        <div
          key={index}
          className="bg-gray-800 rounded-lg shadow-md overflow-hidden"
        >
          <button
            className="w-full flex justify-between items-center p-6 text-left text-xl font-semibold text-white hover:bg-gray-700 transition duration-300"
            onClick={() => toggleAccordion(index)}
          >
            {faq.question}
            {activeIndex === index ? (
              <FaChevronUp className="text-gray-400" />
            ) : (
              <FaChevronDown className="text-gray-400" />
            )}
          </button>
          {activeIndex === index && (
            <div className="p-6 text-gray-300 border-t border-gray-700">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Faqs;
