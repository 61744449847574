// src/components/Hero.js
import React from 'react';
import heroImage from '../assets/Hero.png'; // Importamos la imagen desde assets
import Faqs from './Faqs'; // Importamos el componente Faqs

const Hero = () => {
  return (
    <div>
      {/* Sección Hero con imagen de fondo */}
      <div
        className="relative bg-cover bg-center h-screen flex items-center justify-center text-white"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div> {/* Capa oscura */}

        <div className="relative z-10 text-center px-6">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            ¡Accede a todo el contenido y aprende comedia!
          </h1>
          <p className="text-lg md:text-xl mb-8">
            Descubre los secretos del stand-up, talleres prácticos, y mucho más con nuestro contenido exclusivo.
          </p>

          <form className="flex flex-col sm:flex-row justify-center gap-4">
            <input
              type="email"
              placeholder="Ingresa tu email"
              className="px-4 py-3 rounded-md text-black outline-none w-full sm:w-80"
            />
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-6 py-3 rounded-md transition duration-300"
            >
              Comenzar
            </button>
          </form>
        </div>
      </div>

      {/* Sección FAQS con fondo negro */}
      <div className="bg-black py-20">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-3xl font-extrabold text-white text-center mb-12">
            Preguntas Frecuentes
          </h2>
          <Faqs /> {/* Renderizamos las preguntas frecuentes */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
