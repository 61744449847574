import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import MiPerfil from './components/MiPerfil';
import Classroom from './components/Classroom';
import Hero from './components/Hero';
import ProductsGrid from './components/ProductsGrid';

function App() {
  const videoUrl = 'https://comedylabcr.com/wp-content/uploads/Comedia Express/16 - Clase 2 - Ejercicio de Creación de Sorpresas Refranes Cambiados.mp4'; // URL de video de ejemplo

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/perfil" element={<MiPerfil />} />
          <Route path="/classroom" element={<Classroom videoUrl={videoUrl} />} />
          <Route path="/productos" element={<ProductsGrid />} />
          
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
