import React from 'react';
import { ReactComponent as Logo } from '../assets/logo-comedylacr.svg';

const Header = () => {
  return (
    <header className="bg-[#0A1E44] text-white">
      <div className="container mx-auto flex items-center justify-between py-4 px-6">
        {/* Logo */}
        <div className="flex items-center">
          <Logo className="w-10 h-10 mr-2" />
        </div>

        {/* Links del menú */}
        <nav className="flex space-x-8">
          <a href="/" className="hover:text-blue-400">Inicio</a>
          <a href="/productos" className="hover:text-blue-400">Clases Privadas</a>
          <a href="/classroom" className="hover:text-blue-400">Nuestros Cursos</a>
          <a href="/contacto" className="hover:text-blue-400">Contáctenos</a>
          <a href="/perfil" className="hover:text-blue-400">Mi Cuenta</a>
        </nav>

        {/* Icono de cuenta o carrito */}
        <div className="relative">
          <span className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">0</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 15v-3m0 0V9m0 3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Header;
