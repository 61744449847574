import React from 'react';
import { ReactComponent as Logo } from '../assets/footer-comedylabcr.svg';

const Footer = () => {
  return (
    <footer className="bg-[#0A1E44] text-white py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-6">
        {/* Sección 1: Información General */}
        <div>
          <h3 className="text-lg font-bold mb-4">COMEDY LAB COSTA RICA</h3>
          <p className="text-sm">
            Cursos de Stand Up Comedy, Talleres de Comedia, capacitación en escritura humorística, escritura de chistes,
            creación de humor, aprenda a hacer monólogos de comedia en Costa Rica.
          </p>
        </div>

        {/* Sección 2: Métodos de Pago */}
        <div>
          <h3 className="text-lg font-bold mb-4">MÉTODOS DE PAGO</h3>
          <p className="text-sm mb-2">Acceda a nuestros cursos y asesorías privadas realizando pagos por medio de nuestras plataformas de pago:</p>
          <ul className="space-y-2">
            <li className="flex items-center space-x-2">
              <img src="https://img.icons8.com/ios-filled/50/ffffff/paypal.png" alt="PayPal" className="w-6 h-6" />
              <span>PayPal</span>
            </li>
            <li className="flex items-center space-x-2">
              <img src="https://img.icons8.com/ios-filled/50/ffffff/money-transfer.png" alt="Sinpe Móvil" className="w-6 h-6" />
              <span>Sinpe Móvil</span>
            </li>
            <li className="flex items-center space-x-2">
              <img src="https://img.icons8.com/ios-filled/50/ffffff/bank.png" alt="Transferencia Bancaria" className="w-6 h-6" />
              <span>Transferencia Bancaria</span>
            </li>
          </ul>
        </div>

        {/* Sección 3: Ayuda */}
        <div>
          <h3 className="text-lg font-bold mb-4">NECESITA AYUDA?</h3>
          <ul className="space-y-2">
            <li><a href="/privacidad" className="hover:text-blue-400">Políticas de Privacidad</a></li>
            <li><a href="/terminos" className="hover:text-blue-400">Términos y Condiciones</a></li>
            <li><a href="/cursos" className="hover:text-blue-400">Nuestros Cursos</a></li>
            <li><a href="/problemas" className="hover:text-blue-400">Problemas con la Plataforma</a></li>
            <li><a href="/faq" className="hover:text-blue-400">Preguntas Frecuentes</a></li>
            <li><a href="/reset-password" className="hover:text-blue-400">Restablecer Contraseña</a></li>
          </ul>
        </div>

        {/* Sección 4: Logo */}
        <div className="flex justify-center md:justify-end items-center">
          <Logo className="w-40" />
        </div>
      </div>

      {/* Copyright */}
      <div className="bg-[#071633] py-4 mt-8">
        <p className="text-center text-sm">
          COMEDY LAB COSTA RICA 2024 | DESARROLLADO POR KUV DIGITAL
        </p>
      </div>
    </footer>
  );
};

export default Footer;
