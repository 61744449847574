// src/apirest/woocomerce.js
import axios from 'axios';

// Configuración de la API de WooCommerce
const woocommerceApi = axios.create({
  baseURL: 'https://comedylabcr.com/wp-json/wc/v3/', // Reemplaza por tu URL
  auth: {
    username: 'ck_94f9fe42ef2835ba9b773fc289f2c8a0087f91f8', // Reemplaza con tu consumer key
    password: 'cs_6cb44c120dd095cd54c10c3a1f79e44f19cd63ab', // Reemplaza con tu consumer secret
  },
});

export const getProducts = async () => {
  try {
    const response = await woocommerceApi.get('products'); // Ruta de productos
    return response.data;
  } catch (error) {
    console.error('Error al obtener los productos:', error);
    return [];
  }
};
