import React, { useState, useRef } from 'react';
import { FaPlay, FaPause, FaRedoAlt, FaVolumeUp, FaVolumeMute, FaEllipsisV } from 'react-icons/fa';

const Classroom = ({ videoUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0); // Estado del progreso en %
  const [volume, setVolume] = useState(1); // Estado del volumen
  const videoRef = useRef(null); // Referencia al video

  const togglePlay = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = () => {
    const video = videoRef.current;
    const progressValue = (video.currentTime / video.duration) * 100;
    setProgress(progressValue);
  };

  const handleVolumeChange = () => {
    const newVolume = volume === 0 ? 1 : 0;
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      {/* Sidebar con lista de clases */}
      <aside className="w-1/4 bg-blue-900 text-white p-6 flex flex-col justify-between">
        <div>
          <h2 className="text-2xl font-bold mb-4">Clases</h2>
          <ul className="space-y-3">
            <li><a href="#" className="block hover:text-blue-300">Introducción al curso</a></li>
            <li><a href="#" className="block hover:text-blue-300">Clase 1: Fundamentos del Stand Up</a></li>
            <li><a href="#" className="block hover:text-blue-300">Clase 2: Técnicas de Escritura</a></li>
            <li><a href="#" className="block hover:text-blue-300">Clase 3: Ejercicios Prácticos</a></li>
            <li><a href="#" className="block hover:text-blue-300">Clase 4: Presentación Final</a></li>
          </ul>
        </div>

        {/* Barra de Progreso */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Progreso del Curso</h3>
          <div className="w-full bg-gray-700 rounded-full h-4">
            <div className="bg-green-500 h-4 rounded-full" style={{ width: `${progress}%` }}></div>
          </div>
          <p className="mt-2 text-sm text-gray-300">{Math.round(progress)}% completado</p>
        </div>
      </aside>

      {/* Contenedor del Video */}
      <main className="flex-1 p-8">
        <div className="mb-4">
          <h1 className="text-3xl font-bold">Introducción al curso</h1>
          <p className="text-gray-400 mt-2">
            En esta lección, aprenderás los fundamentos del Stand Up Comedy, incluyendo cómo estructurar tus ideas y preparar un monólogo efectivo.
          </p>
        </div>

        <div className="bg-black rounded-lg shadow-lg overflow-hidden">
          <video
            ref={videoRef}
            onTimeUpdate={handleProgress}
            className="w-full"
            src={videoUrl}
            controls={false} // Ocultamos los controles nativos
          ></video>

          {/* Controles personalizados */}
          <div className="flex items-center justify-between p-4 bg-gray-800 rounded-b-lg">
            <div className="flex items-center space-x-4">
              <button onClick={togglePlay} className="text-white">
                {isPlaying ? <FaPause size={24} /> : <FaPlay size={24} />}
              </button>
              <button onClick={() => (videoRef.current.currentTime -= 15)} className="text-white">
                <FaRedoAlt size={24} /> -15s
              </button>
              <button onClick={() => (videoRef.current.currentTime += 15)} className="text-white">
                <FaRedoAlt size={24} /> +15s
              </button>
            </div>

            {/* Barra de progreso */}
            <div className="flex-1 mx-4">
              <input
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={(e) => (videoRef.current.currentTime = (videoRef.current.duration * e.target.value) / 100)}
                className="w-full h-2 bg-blue-600 rounded-lg appearance-none"
              />
            </div>

            <div className="flex items-center space-x-4">
              <span>{Math.floor(videoRef.current?.currentTime || 0)}s</span>
              <button onClick={handleVolumeChange} className="text-white">
                {volume === 0 ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
              </button>
              <button className="text-white">
                <FaEllipsisV size={24} />
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Classroom;
